<template>
    <OModal name="NewBidModal" @shown="newBidModalOpen" @hidden="newBidModalClose" ref="newBidModalRef">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add New Bid</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group col-12">
                        <label>{{ $t("Org Unit (where should the new Bid be placed)") }}</label>
                        <OOrgunitsLookup :bind="sel=>{ newBidObject.OrgUnitID=sel.ID; newBidObject.OrgUnit=sel.Name }">
                            <template #orgunit>
                                <input type="text" class="form-select" :class="{'is-invalid' : showModalValidation && !newBidObject.OrgUnitID}" ref="ref_OrgUnitInput" v-model="newBidObject.OrgUnit">
                            </template>
                        </OOrgunitsLookup>
                        <div v-if="showModalValidation && !newBidObject.OrgUnitID" class="text-danger small">{{ $t('Please select a OrgUnit.') }}</div>
                    </div>
                    <div class="form-group col-12" :title="$t('Use a name that identifies / describes the bid (Client name has its own field).')">
                        <label>{{ $t("Bid Name / Title") }}</label>
                        <input type="text" class="form-control form-control-sm" :class="{'is-invalid' : showModalValidation && !newBidObject.BidName}" v-model="newBidObject.BidName">
                        <div v-if="showModalValidation && !newBidObject.BidName" class="text-danger small">{{ $t('Please enter Bid Name / Title.') }}</div>
                    </div>

                    <div class="form-group col-12" :title="$t('If this is not decided, use your own name.')">
                        <label>{{ $t("Opportunity Owner") }}</label>
                        <OPersonsLookup class="form-control form-control-sm"
                                        :class="{'is-invalid' : showModalValidation && !newBidObject.OpportunityOwnerID}"
                                        :bind="sel => { newBidObject.OpportunityOwnerID=sel.ID; newBidObject.OpportunityOwner=sel.Name;}"
                                        v-model="newBidObject.OpportunityOwner"
                        ></OPersonsLookup>
                        <div v-if="showModalValidation && !newBidObject.OpportunityOwnerID" class="text-danger small">{{ $t('Please select Opportunity Owner.') }}</div>
                    </div>

                    <div class="form-group col-12 mb-0" :title="$t('If the client is registered in the System Company register, you can tick the Existing client box and select it from the search. If not, enter the correct client name.')">
                        <label class="ml-0">
                            <input type="checkbox" v-model="newBidObject.ExistingClient">
                            {{ $t("Existing Client") }}
                        </label>
                    </div>

                    <div class="form-group col-12" :title="$t('If the client is registered in the System Company register, you can tick the Existing client box and select it from the search. If not, enter the correct client name.')">
                        <label>{{ $t("Client") }}</label>
                        <input type="text" class="form-control form-control-sm" v-model="newBidObject.Client" v-if="!newBidObject.ExistingClient">
                        <ODataLookup
                            class="form-control form-control-sm" 
                            :data-object="dsClientsLkp" 
                            v-model="newBidObject.Client" 
                            :bind="sel=>{ newBidObject.ClientID=sel.ID; newBidObject.Client=sel.Name; }"
                            v-else
                        >
                            <o-column field="ID" :headerName="$t('ID')" :headerTitle="$t('ID')" width="80"/>
                            <o-column field="Name" :headerName="$t('Name')" :headerTitle="$t('Name')" width="300"/>
                        </ODataLookup>
                    </div>

                    <div class="form-group col-12" :title="$t('Information request - Stages: Information request from client / Information to client; Quick quote - Stages: Information from client / Quote / Negotiation and agreement; Competitive bid: 4 standard stages; Large competitive bid with prequalification: 7 stages')">
                        <label>{{ $t("Select Process") }}</label>
                        <ODataLookup
                            class="form-control form-control-sm" 
                            :data-object="dsProcessLkp" 
                            v-model="newBidObject.Process" 
                            :bind="sel=>{ newBidObject.ProcessID=sel.ID; newBidObject.Process=sel.Name; }"
                        >
                            <o-column field="ID" :headerName="$t('ID')" :headerTitle="$t('ID')" width="80"/>
                            <o-column field="Name" :headerName="$t('Name')" :headerTitle="$t('Name')" width="300"/>
                        </ODataLookup>
                    </div>

                    <div class="form-group col-12" :title="$t('Enter a date where you, based on present knowledge, believe the client will have a decision. Can be corrected later in the Info tab')">
                        <label>{{ $t("Award Expected Before") }}</label>
                        <ODatePicker  
                            v-model="newBidObject.AwardExpectedBefore"
                            format="Short Date"
                            :clearable="false"
                            clearableFromPicker
                            :showInputIcon="true"
                            class="form-control"/>
                    </div>

                    <div class="form-group col-12" :title="$t('Total Contract Value - the expected revenue from contract over the base contract period without prolongations. If there is no defined contract period, use revenue over 3 years. Can be updated later in the Info tab.')">
                        <label>{{ $t("Expected TCV") }}</label>
                        <ODataLookup
                            class="form-control form-control-sm" 
                            :data-object="dsExpectedTCVLkp" 
                            v-model="newBidObject.ExpectedTCVSpan" 
                            :bind="sel=>{ newBidObject.ExpectedTCVSpanID=sel.ID; newBidObject.ExpectedTCVSpan=sel.Span; }"
                        >
                            <o-column field="Span" :headerName="$t('Span')" :headerTitle="$t('Span')" width="300"/>
                        </ODataLookup>
                    </div>

                    <div class="form-group col-12" :title="$t('Use multiple tags to describe the content of the proposal / bid')">
                        <label>{{ $t("Tags / Keywords") }}</label>
                        <ODataLookup
                            :data-object="dsTagsLkp"
                            :bind="generateTagsList"
                            v-model="newBidObject.Tags"
                            multiselect readonly
                            class="form-select">
                            <OColumn field="Tag" :headerName="$t('Tag')" width="400"/>
                        </ODataLookup>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="CreateNewBid">Save</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import { ref } from 'vue';
    import ODataLookup from 'o365.vue.components.DataLookup.vue'
    import OOrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue';
    import OPersonsLookup from 'o365.vue.components.PersonsLookup.vue';
    import alert from 'o365.controls.alert.ts';

    const props = defineProps({
        redirectOnCreate: {
            type: Boolean,
            required: false,
            default: true,
        },
        functionOnCreate: {
            type: Function,
            required: false,
            default: () => {},
        },
        leadID: {
            type: Number,
            required: false,
            default: null,
        },
        isBid: {
            type: Boolean,
            required: false,
            default: true,
        }
    });
    const showModalValidation = ref(false);

    const dsClientsLkp = getOrCreateDataObject({
        id: 'dsClients' + crypto.randomUUID(),
        viewName: 'sviw_System_Companies',
        maxRecords: -1,
        whereClause: "",
        loadRecents: false,
        distinctRows: false,
        fields: [
            { name: "ID", type: "number" },
            { name: "Name", type: "string"}
        ]
    });

    const dsProcessLkp = getOrCreateDataObject({
        id: 'dsProcessLkp' + crypto.randomUUID(),
        viewName: 'atbv_Contract_ProcurementProcesses',
        maxRecords: -1,
        whereClause: "ForBid = 1",
        loadRecents: false,
        distinctRows: false,
        fields: [
            { name: "ID", type: "number" },
            { name: "Name", type: "string", "sortOrder": 1, "sortDirection": "asc" }
        ]
    });

    const dsExpectedTCVLkp = getOrCreateDataObject({
        id: 'dsExpectedTCVLkp' + crypto.randomUUID(),
        viewName: 'atbv_Contract_ProcurementExpectedTCVSpans',
        maxRecords: -1,
        whereClause: "",
        loadRecents: false,
        distinctRows: false,
        fields: [
            { name: "ID", type: "number" },
            { name: "Span", type: "string"}
        ]
    });

    const dsTagsLkp = getOrCreateDataObject({
        id: 'dsTagsLkp' + crypto.randomUUID(),
        viewName: 'atbv_Contract_ProcurementTags',
        maxRecords: -1,
        whereClause: "",
        loadRecents: false,
        distinctRows: false,
        fields: [
            { name: "ID", type: "number", "sortOrder": 1, "sortDirection": "asc" },
            { name: "Tag", type: "string"}
        ]
    });
    const newBidModalRef = ref(null);
    const newBidObject = ref({
        OrgUnit: null,
        OrgUnitID: null,
        BidName: null,
        BidID: null,
        OpportunityOwner: null,
        OpportunityOwnerID: null,
        ExistingClient: null,
        Client: null,
        ClientID: null,
        Process: null,
        ProcessID: null,
        AwardExpectedBefore: null,
        ExpectedTCVSpan: null,
        ExpectedTCVSpanID: null,
        Tags: null
    });

    function generateTagsList(pArray) {  
        if(pArray && pArray.length === 0) {
            newBidObject.value["Tags"] = null;
            return;
        }

        let vTags = "";
        pArray.forEach((object) => {
            if (object.hasOwnProperty("Tag")) {
                if(vTags.length > 0) {
                    vTags += ", " + object.Tag;
                } else {
                    vTags = object.Tag;
                }
            }
        })
        newBidObject.value["Tags"] = vTags;
    }

    function newBidModalOpen(){
        console.log("open");
    }

    function newBidModalClose(){
        console.log("close");
        for (let key in newBidObject.value) {
            if (newBidObject.value.hasOwnProperty(key)) {
                newBidObject.value[key] = null;
            }
        }
        showModalValidation.value = false;
    }

    const procNewBid = getOrCreateProcedure({ id:"procNewBid" + crypto.randomUUID(), procedureName: "astp_Contract_NewProcurement" });

    function CreateNewBid(){
        showModalValidation.value = true;
        if(!newBidObject.value.OrgUnitID || !newBidObject.value.BidName || !newBidObject.value.OpportunityOwnerID) {
            return;
        }
        const loadingToast = alert($t('Creating New Bid...'), 'info');
        
        procNewBid.execute({
            OrgUnit_ID: newBidObject.value.OrgUnitID,
            Name: newBidObject.value.BidName,
            IsBid: 1,
            DoNotInherit: 0,
            OpportunityOwner_ID: newBidObject.value.OpportunityOwnerID,
            Client_ID: newBidObject.value.ExistingClient ? newBidObject.value.ClientID : null,
            Client: !newBidObject.value.ExistingClient ? newBidObject.value.Client : null,
            Process_ID: newBidObject.value.ProcessID,
            AwardExpectedBefore: newBidObject.value.AwardExpectedBefore,
            ExpectedTCVSpan_ID: newBidObject.value.ExpectedTCVSpanID,
            Tags: newBidObject.value.Tags,
            Lead_ID: props.leadID
        }).then((pResponse) => {
            newBidModalRef.value.hide();
            loadingToast.close();
            props.functionOnCreate(pResponse.Table[0].ID);
            
            if(pResponse.Table[0].ID && props.redirectOnCreate) {
                window.location.replace(`/contract-procurement?ID=${pResponse.Table[0].ID}&ParentApp=bids`);
            }
        }).catch(() => {
            loadingToast.close();
        });
    }

    function openModal(){
        newBidModalRef.value.show();
    }

    defineExpose({
        openModal
    });

</script>